import { getRequest, postRequest, putRequest, patchRequest } from "../../../sw-studio-core/src/utils/utility";

let genericUrlInstitutions = window.APP_CONFIG.URL_API + 'institutions/'

function formatUTCDate(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

export function getChartData(institutionId, equipmentId, startDate, endDate, gmt, download, type, sensorType, aggregateByStatus, successCallback, errorCallback) {

    let url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments/' + equipmentId + '/data';
    let queryParams = [];
    let extraHeaders = {};
    queryParams.push(encodeURIComponent("startDate") + "=" + encodeURIComponent(formatUTCDate(startDate)));
    queryParams.push(encodeURIComponent("endDate") + "=" + encodeURIComponent(formatUTCDate(endDate)));
    if(download){
        extraHeaders = {responseType: 'blob'};
        queryParams.push(encodeURIComponent("download") + "=" + encodeURIComponent(true));
        queryParams.push(encodeURIComponent("gmt") + "=" + encodeURIComponent(gmt));
    }
    queryParams.push(encodeURIComponent("np") + "=" + encodeURIComponent(1000));
    queryParams.push(encodeURIComponent("type") + "=" + encodeURIComponent(type));
    queryParams.push(encodeURIComponent("sensorType") + "=" + encodeURIComponent(sensorType));

    aggregateByStatus?.forEach(status => {
        queryParams.push(encodeURIComponent("aggregateByStatus[]") + "=" + encodeURIComponent(status));
    });

    url += "?" + queryParams.join("&");

    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error),
        '',
        extraHeaders
    );
}

export function getEquipments(
    institutionId,
    filters = {},
    successCallback,
    errorCallback = () => {
    },
    groupId
) {
    let url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments';
    let queryParams = [];
    if (groupId !== undefined && groupId !== null) {
        queryParams.push("group=" + encodeURIComponent(groupId));
    }
    for (const key in filters) {
        if (filters.hasOwnProperty(key) && filters[key] !== undefined && filters[key] !== null) {
            queryParams.push(encodeURIComponent(key) + "=" + encodeURIComponent(filters[key]));
        }
    }
    if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
    }
    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error)
    )
}

export function getEquipment(
    institutionId,
    equipmentId,
    successCallback,
    errorCallback = () => {
    },
    groupId
) {
    let url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments/' + equipmentId;
    let queryParams = [];
    if (groupId !== undefined && groupId !== null) {
        queryParams.push("group=" + encodeURIComponent(groupId));
    }
    if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
    }
    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error)
    )
}

export function getEquipmentIndicators(
    institutionId,
    equipmentId,
    period,
    successCallback,
    errorCallback = () => {
    },
    groupId
) {
    let url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments/' + equipmentId + "/indicators";
    let queryParams = [];
    if (groupId !== undefined && groupId !== null) {
        queryParams.push("group=" + encodeURIComponent(groupId));
    }
    if(period && period.startDate) {
        queryParams.push("startDate=" + encodeURIComponent(formatUTCDate(period.startDate)));
    }
    if(period && period.endDate) {
        queryParams.push("endDate=" + encodeURIComponent(formatUTCDate(period.endDate)));
    }
    if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
    }
    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error)
    )
}

export function getEquipmentEventsHistory(
    institutionId,
    equipmentId,
    params,
    filters = {},
    successCallback,
    errorCallback = () => {
    },
    groupId
) {
    let url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments/' + equipmentId + "/eventsHistory";
    let queryParams = [];
    let extraHeaders = {};
    const { period, search, filterEventType, download } = params;
    if (groupId !== undefined && groupId !== null) {
        queryParams.push("group=" + encodeURIComponent(groupId));
    }
    if(period && period.startDate) {
        queryParams.push("startDate=" + encodeURIComponent(formatUTCDate(period.startDate)));
    }
    if(period && period.endDate) {
        queryParams.push("endDate=" + encodeURIComponent(formatUTCDate(period.endDate)));
    }
    if(search) {
        queryParams.push("userName=" + encodeURIComponent(search));
    }
    if(filterEventType) {
        queryParams.push("eventType=" + encodeURIComponent(filterEventType));
    }
    if(download) {
        extraHeaders = {responseType: 'blob'};
        queryParams.push("download=" + encodeURIComponent(download));
    }

    for (const key in filters) {
        if (filters.hasOwnProperty(key) && filters[key] !== undefined && filters[key] !== null) {
            queryParams.push(encodeURIComponent(key) + "=" + encodeURIComponent(filters[key]));
        }
    }

    if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
    }
    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error),
        '',
        extraHeaders
    )
}

export function getEquipmentSensor(
    institutionId,
    equipmentId,
    successCallback,
    errorCallback = () => {
    },
    groupId
) {
    let url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments/' + equipmentId + "/sensor";
    let queryParams = [];
    if (groupId !== undefined && groupId !== null) {
        queryParams.push("group=" + encodeURIComponent(groupId));
    }
    if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
    }
    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error)
    )
}

export function verifyUserPin(
    institutionId,
    data,
    successCallback,
    errorCallback = () => {
    },
) {
    postRequest(genericUrlInstitutions + institutionId + '/thermometry/alerts/userPinCode',
        data,
        (response) => successCallback(response),
        (error) => errorCallback(error)
    )
}

export function getFormChoices(
    institutionId,
    successCallback,
    errorCallback = () => {
    },
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/alerts/form/'
    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error)
    )
}

export function acquitAlert(
    institutionId,
    alertId,
    token,
    data,
    successCallback,
    errorCallback = () => {
    },
    groupId
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/alerts/' + alertId + '/acquit'
    let headers = {};
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    putRequest(
        url,
        data,
        (response) => successCallback(response),
        (error) => errorCallback(error),
        headers
    )
}

export function updateAlertAcknowledge(
    institutionId,
    acknowledgeId,
    token,
    data,
    successCallback,
    errorCallback = () => {
    },
    groupId
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/acknowledge/' + acknowledgeId
    let headers = {};
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    putRequest(
        url,
        data,
        (response) => successCallback(response),
        (error) => errorCallback(error),
        headers
    )
}

export function editAlertEquipment(
    institutionId,
    equipmentId,
    body,
    successCallback,
    errorCallback = (error) => {
        console.error(error)
    }
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments/' + equipmentId + "/edit"
    postRequest(
        url,
        body,
        successCallback,
        errorCallback
    )
}

export function getAlert(
    institutionId,
    alertId,
    successCallback,
    errorCallback = (error) => {
        console.error(error)
    }
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/alerts/details/' + alertId
    getRequest(
        url,
        successCallback,
        errorCallback
    )
}

export function editAlert(
    institutionId,
    alertId,
    body,
    successCallback,
    errorCallback = (error) => {
        console.error(error)
    }
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/alerts/' + alertId + "/edit"
    postRequest(
        url,
        body,
        successCallback,
        errorCallback
    )
}

export function addComment(
    institutionId,
    equipmentId,
    body,
    successCallback,
    errorCallback = (error) => {
        console.error(error)
    }
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments/' + equipmentId + '/events/comment'
    postRequest(
        url,
        body,
        successCallback,
        errorCallback
    )
}

export function getEquipmentsSuggestions(
    institutionId,
    successCallback,
    errorCallback = () => {
    },
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/alerts/equipments/search/suggestions/'
    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error)
    )
}

export function getUnitsFormats(
    institutionId,
    successCallback,
    errorCallback = () => {
    }
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/units'
    getRequest(
        url,
        (response) => successCallback(response),
        (error) => errorCallback(error)
    )
}

export function inhibitionEquipment(
    institutionId,
    equipmentId,
    body,
    successCallback,
    errorCallback = (error) => {
        console.error(error)
    }
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/' + equipmentId + '/inhibition'
    postRequest(
        url,
        body,
        successCallback,
        errorCallback
    )
}

export function disinhibitionEquipment(
    institutionId,
    equipmentId,
    data,
    successCallback,
    errorCallback = (error) => {
        console.error(error)
    }
) {
    const url = genericUrlInstitutions + institutionId + '/thermometry/' + equipmentId + '/inhibition'
    patchRequest(
        url,
        data,
        successCallback,
        errorCallback
    )
}