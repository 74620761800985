const dashboardModule = {
    state: {
        selectedScenario: null
    },
    mutations: {
        setSelectedScenario(state, scenario) {
            state.selectedScenario = scenario;
        }
    }
}

export default dashboardModule;